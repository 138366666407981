import React from 'react'
import routes from '../routes'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import Layout from '../ui/layout/layout'
import SEO from '../ui/components/seo/seo'
import Text from '../ui/elements/text/text'
import Button from '../ui/elements/button/button'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './404.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const NotFoundPage = ({ location }) => (
	<Layout location={location}>
		<SEO title="Not found" description="This page is unavailable" url={routes.notFound()} />

		<div className={styles.Wrapper}>
			<div className={styles.TextWrapper}>
				<Text as="h1" size="36" chunky>
					The dog ate our homework!
				</Text>
				<Text as="p" size="24" className={styles.MiddleText}>
					Oops, this page doesn't exist, but there's plenty more to explore...
				</Text>
				<Button as="link" large to={routes.index()}>
					Go back home
				</Button>
			</div>
			<div className={styles.ImageWrapper} />
		</div>
	</Layout>
)

export default NotFoundPage
